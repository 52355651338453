.map-marker-custom-pin {
  width: 34px;
  height: 34px;
  color: white;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  position: relative;

  transition: all 0.2s ease-in-out;
}
.map-marker-custom-pin.is-new:after {
  width: 34px;
  height: 34px;
  background-color: white;
  border-radius: 50%;
  position: relative;
  top: 0;
  left: 0;
  display: block;
  content: " ";
  z-index: 10;

  opacity: 0.5;
}
.map-marker-custom-pin.highlighted {
  transform: scale(1.4);
}

.map-marker-inner-circle {
  width: 28px;
  height: 28px;

  position: absolute;
  top: 3px;
  left: 3px;
  border: 4px solid white;
  border-radius: 50%;
  padding-top: 3px;
  font-size: 0.75rem;

  transition: all 0.2s ease-in-out;
}

.map-marker-custom-pin.notify-changed,
.map-marker-custom-pin.notify-changed .map-marker-inner-circle {
  background-color: green;
}

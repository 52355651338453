/* Tailwind Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f7f9; /*#daf3ff;*/
  font-family: Roboto, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
textarea {
  outline: none;
}
.ui-placeholder {
  position: static;
  overflow: hidden;
  -webkit-animation: placeholderShimmer 2s linear;
  animation: placeholderShimmer 2s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-color: #fff;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.06) 0,
    rgba(0, 0, 0, 0.1) 15%,
    rgba(0, 0, 0, 0.06) 30%
  );
  background-size: 1200px 100%;
  /* max-width: 30rem; */
}
@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
@keyframes placeholderShimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
.basic-input:user-invalid {
  @apply border-snow-red;
}

.css-checkbox {
  font-family: system-ui, sans-serif;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  margin-top: 5px;
  margin-bottom: 5px;
}

.css-checkbox + .css-checkbox {
  margin-top: 1em;
}

.css-checkbox--disabled {
  color: gray;
  cursor: not-allowed;
}

.css-checkbox input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  grid-template-columns: auto;
  place-content: center;
}

.css-checkbox input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em black;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

.css-checkbox input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.css-checkbox input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

.css-checkbox input[type="checkbox"]:disabled {
  color: rgb(175, 175, 175);
  cursor: not-allowed;
}
.css-checkbox input[type="checkbox"]:disabled::before {
  box-shadow: inset 1em 1em rgb(175, 175, 175);
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

@keyframes flashElement {
  0% {
    box-shadow: inherit;
  }
  50% {
    box-shadow: 0 0 25px blue;
  }
  100% {
    box-shadow: inherit;
  }
}
.terms ol {
  list-style: decimal;
  margin-left: 20px;
}
.terms ol ol {
  list-style: upper-alpha;
}
.terms li {
  margin-bottom: 5px;
}
